import React, { Component } from 'react';
import './RowItem.css';

class RowItem extends Component {


  render() {

    let content = <div className="RowItem">

      <img src={this.props.icon} alt={this.props.label}/>

      <p>{this.props.text}</p>

    </div>

    return content;

  }//render


}//RowItem

export default RowItem;
