import React, { Component } from 'react';
import './App.css';

import Column from './components/Column';
import Row from './components/Row';
import sienergieLogo from './assets/images/sienergie.png';


class App extends Component {


  render() {

    let content = <div className="App">

      <Column type={1} />
      <Row type={1} />
      <Column type={2} />
      <Row type={2} />
      <Column type={3} />

      <div className="footer">
        <p className="measureKit">MeasureKit</p>
        <img className="sienergie" src={sienergieLogo} alt="Sienergie" />
      </div>

    </div>

    return content;

  }//render


}//App

export default App;
