import React, { Component } from 'react';
import './Row.css';

import RowItem from './RowItem';

import batteryIcon from '../assets/images/battery.png';
import simIcon from '../assets/images/sim.png';
import privacyIcon from '../assets/images/privacy.png';

import industryIcon from '../assets/images/industry.png';
import hardwareIcon from '../assets/images/hardware.png';
import invisibiliIcon from '../assets/images/invisibili.png';


class Row extends Component {


  getRow1() {

    let text1 = "Funzionamento a batteria fino a 60 giorni.";
    let text2 = "Trasmissione dei dati in piena autonomia tramite sim card.";
    let text3 = "Nessun problema di privacy: tutti i dati sono anonimi.";

    let content = <div className="Row">

      <RowItem label="batteria" icon={batteryIcon} text={text1} />
      <RowItem label="sim" icon={simIcon} text={text2} />
      <RowItem label="privacy" icon={privacyIcon} text={text3} />

    </div>

    return content;

  }//getRow1


  getRow2() {

    let text1 = "Measure kit è una tecnologia Industry 4.0 ready.";
    let text2 = "L’hardware può essere riutilizzato in nuovi espositori";
    let text3 = "I sensori sono invisibili e non pregiudicano l’estetica.";

    let content = <div className="Row">

      <RowItem label="industry 4.0" icon={industryIcon} text={text1} />
      <RowItem label="hardware" icon={hardwareIcon} text={text2} />
      <RowItem label="invisibili" icon={invisibiliIcon} text={text3} />

    </div>

    return content;

  }//getRow2


  render() {

    if(this.props.type === 1){
      return this.getRow1();
    }else if(this.props.type === 2){
      return this.getRow2();
    }

  }//render


}//Row

export default Row;
