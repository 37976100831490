import React, { Component } from 'react';
import './Column.css';

import Title from './Title';
import Box from './Box';

import geolocalizzazioneIcon from '../assets/images/geolocalizzazione.png';
import flussoIcon from '../assets/images/flusso.png';
import selloutIcon from '../assets/images/sellout.png';

import soldoutIcon from '../assets/images/soldout.png';
import abIcon from '../assets/images/ab.png';

import serverIcon from '../assets/images/server.png';
import sensoriIcon from '../assets/images/sensori.png';
import webappIcon from '../assets/images/webapp.png';


class Column extends Component {


  getColumn1() {

    let question = "Quali dati genera measure kit?";

    let title1 = "Geolocalizzazione";
    let text1 = "E’ possibile geolocalizzare l’espositore da quando lascia lo stabilimento produttivo, fino alla sua attivazione nel punto vendita. Avendo in automatico il tempo di transito e la data di rimozione dall’imballo.";

    let title2 = "Visibilità";
    let text2 = "Il flusso di passaggio in prossimità dell’espositore viene misurato e registrato quotidianamete suddividendolo in fasce orarie.";

    let title3 = "Sell out";
    let text3 = "Monitorando lo stato di riempimento dell’espositore è possibile stimare il sell out in tempo reale differenziando i dati per ogni referenza presente.";

    let content = <div className="Column">

      <div className="singleColumn">
        <Title text={question} />
        <Box type="a" title={title1} text={text1} image={geolocalizzazioneIcon} imageType="big_center" />
      </div>

      <div className="singleColumn">
        <Box type="b" title={title2} text={text2} image={flussoIcon} imageType="full_bottom" />
        <Box type="c" title={title3} text={text3} image={selloutIcon} imageType="medium_center" />
      </div>

    </div>

    return content;

  }//getColumn1


  getColumn2() {

    let question = "Quali sono i possibili casi d'uso?";

    let title1 = "Feedback su promozioni in atto";
    let text1 = "E’ possibile avere un riscontro immediato di come campagne pubblicitarie, sia tradizionali (cartellonistica, inserzioni, radio), sia digital (campagne social localizzate),  influenzino le vendite nei vari territori presi in esame.";

    let title2 = "Prevenire la rottura di stock";
    let text2 = "Avendo a disposizione il dato sull’andamento delle vendite è possibile prevedere in anticipo quando l’espositore sarà vuoto, ricevendo degli alert personalizzati. Sarà quindi possibile agire preventivamente.";

    let title3 = "Test A/B";
    let text3 = "Paragonando le performance di due o più gruppi di espositori, diversi tra loro, è possibile capire quale offra migliori risultati. E quindi prendere decisioni consapevoli sulla grafica, sul design o sul planogramma dell’espositore dopo pochi giorni dall’arrivo nel punto vendita.";

    let title4 = "Monitoraggio trasporto";
    let text4 = "I dati sulla posizione geografica permettono di tenere traccia dei tempi di spostamento, ma è possibile anche conoscere la temperatura a cui viaggia la merce e se riceve urti particolarmente gravi.";


    let content = <div className="Column">

      <div className="singleColumn">
        <Box type="a" title={title1} text={text1} />
        <Box type="c" title={title2} text={text2} image={soldoutIcon} imageType="medium_out_left" />
      </div>

      <div className="singleColumn">
        <Title text={question} />
        <Box type="b" title={title3} text={text3} image={abIcon} imageType="medium_center" />
        <Box type="a" title={title4} text={text4} />
      </div>

    </div>

    return content;

  }//getColumn2


  getColumn3() {

    let question = "Come funziona il sistema?";

    let title1 = "Server cloud";
    let text1 = "Il server riceve i dati dagli espositori presenti nei punti vendita, li registra, ed elabora analisi statistiche e di controllo. L’esito di queste analisi sono poi consultabili tramite web app o integrabili con sistemi informatici realizzati da terze parti.";

    let title2 = "Sensori";
    let text2 = "Gli espositori vengono equipaggiati con diverse tipologie di sensori, i cui dati vengono raccolti dal microcontrollore, che si occupa di inviare i dati al server cloud.";

    let title3 = "Web app";
    let text3 = "I dati raccolti, le statistiche e gli alert, saranno consultabili in piena autonomia tramite la web app, a cui si avrà accesso tramite login protetto da pc e da smartphone.";

    let content = <div className="Column">

      <div className="singleColumn">
        <Title text={question} />
        <Box type="c" title={title1} text={text1} image={serverIcon} imageType="big_center" />
      </div>

      <div className="singleColumn">
        <Box type="a" title={title2} text={text2} image={sensoriIcon} imageType="medium_out_left" />
        <Box type="b" title={title3} text={text3} image={webappIcon} imageType="big_center" />
      </div>

    </div>

    return content;

  }//getColumn3


  render() {

    if (this.props.type === 1) {
      return this.getColumn1();
    } else if (this.props.type === 2) {
      return this.getColumn2();
    } else if (this.props.type === 3) {
      return this.getColumn3();
    }

  }//render


}//Column

export default Column;
