import React, { Component } from 'react';
import './Box.css';

class Box extends Component {


  getImage() {

    if(this.props.image == null) return null;

    let imageClassName = "image";
    if (this.props.imageType) imageClassName += " " + this.props.imageType;

    let content = <div className="imageWrapper">
      <img
        className={imageClassName}
        src={this.props.image}
        alt={this.props.title} />
    </div>

    return content;

  }//getImage


  render() {

    let className = "Box";
    if (this.props.type) className += " " + this.props.type;

    let content = <div className={className}>

      <p className="title">{this.props.title}</p>

      <p className="text">{this.props.text}</p>

      {this.getImage()}

    </div>

    return content;

  }//render


}//Box

export default Box;
