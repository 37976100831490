import React, { Component } from 'react';
import './Title.css';

class Title extends Component {


  render() {

    let content = <div className="Title">

      <p>{this.props.text.toUpperCase()}</p>

    </div>

    return content;

  }//render


}//Title

export default Title;
